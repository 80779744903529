
import adminPanelPages from "@/utils/adminPanelPages.json";
import { DrawersModule } from "@/store/modules/drawers";

import { defineComponent } from "vue";
export default defineComponent({
  name: "AdminPanelDrawer",
  data() {
    return {
      pages: adminPanelPages,
    };
  },
  computed: {
    currentTab(): any {
      return this.$route.params.tab;
    },
  },
  methods: {
    changeTab(path: string) {
      DrawersModule.SetLeftDrawer(false);
      this.$router.push({ params: { tab: path } });
    },
  },
});
